import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'Components/Common/Loader';

const FormImagine: React.FC = () => {
    const { t } = useTranslation();
    const [promptText, setPromptText] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        fetch(`${process.env.REACT_APP_EXPRESS}/imagine`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ promptText: promptText })
        })
        .then(response => response.json())
        .then(data => {
            setImageUrl(data.imageUrl);
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error:', error);
            setIsLoading(false);
        });
    };
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <label htmlFor="text">Texte : </label>
                <textarea
                    id="promptText"
                    name="promptText"
                    value={promptText}
                    onChange={(e) => setPromptText(e.target.value)}
                ></textarea>
                <button type="submit">{t('Send')}</button>
            </form>
            {isLoading ? <Loader /> : <img id="image" src={imageUrl} alt={imageUrl ? "Image générée" : ""} />}
        </React.Fragment>

    );
};

export default FormImagine;
