import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FormDescribe: React.FC = () => {
    const [file1, setFile1] = useState<File | null>(null);
    const { t } = useTranslation();
    const [promptText, setPromptText] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fileSetter: React.Dispatch<React.SetStateAction<File | null>>) => {
        const file = event.target.files ? event.target.files[0] : null;
        fileSetter(file);
    };
    const uploadFiles = () => {
        if (!file1) {
            console.error("Le fichier est requis");
            return;
        }
        const formData = new FormData();
        formData.append('file1', file1);
        formData.append('promptText', promptText); // Ajout de promptText

        fetch(`${process.env.REACT_APP_EXPRESS}/uploadBlend`, {
            method: 'POST',
            body: formData,
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erreur lors de l\'upload du fichier');
            }
            return response.headers.get("content-type")?.includes("application/json")
                   ? response.json()
                   : response.text();
        })
        .then(data => {
            console.log(data);
            // Si vous attendez une URL d'image en réponse, vous pouvez la définir ici
            if (typeof data === 'object' && data.imageUrl) {
                setImageUrl(data.imageUrl);
            }
        })
        .catch(error => {
            console.error('Erreur:', error);
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const url = uploadFiles();
        console.log(url)
    };

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="file1">Fichier : </label>
                    <input type="file" id="file1" onChange={(e) => handleFileChange(e, setFile1)} />
                </div>
                <div>
                    <label htmlFor="text">Texte : </label>
                    <textarea id="promptText" name="promptText"
                        value={promptText}
                        onChange={(e) => setPromptText(e.target.value)}>
                    </textarea>
                </div>
                <button type="submit">{t('Send')}</button>
            </form>
            <img id="image" src={imageUrl} alt={imageUrl ? "Image générée" : ""} />
        </React.Fragment>
    );
};

export default FormDescribe;
