import React, { useState } from 'react';

const FormBlend: React.FC = () => {
    const [file1, setFile1] = useState<File | null>(null);
    const [file2, setFile2] = useState<File | null>(null);
    const [text, setText] = useState('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, fileSetter: React.Dispatch<React.SetStateAction<File | null>>) => {
        const file = event.target.files ? event.target.files[0] : null;
        fileSetter(file);
    };
    const uploadFiles = async () => {
        if (!file1 || !file2) {
            console.error("Les deux fichiers sont requis");
            return null;
        }

        const formData = new FormData();
        formData.append('file1', file1);
        formData.append('file2', file2);

        try {
            const response = await fetch(`${process.env.REACT_APP_EXPRESS}/uploadBlend`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Erreur lors de l\'upload des fichiers');
            }
            return await response.json();
        } catch (error) {
            console.error('Erreur:', error);
            return null;
        }
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const urls = await uploadFiles();
        if (urls) {
            console.log(urls)
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="file1">Fichier 1 : </label>
                <input type="file" id="file1" onChange={(e) => handleFileChange(e, setFile1)} />
            </div>
            <div>
                <label htmlFor="file2">Fichier 2 : </label>
                <input type="file" id="file2" onChange={(e) => handleFileChange(e, setFile2)} />
            </div>
            <div>
                <label htmlFor="text">Texte : </label>
                <textarea id="text" value={text} onChange={(e) => setText(e.target.value)}></textarea>
            </div>
            <button type="submit">Envoyer</button>
        </form>
    );
};

export default FormBlend;
