import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Navbar from '../Landing/OnePage/navbar';
import { useTranslation } from 'react-i18next';
import Footer from 'pages/Landing/OnePage/footer';
import FormImagine from './formImagine';
import FormBlend from './formBlend';
import FormDescribe from './formDescribe';

const Index = () => {
    document.title = "Notre API | The Fast IA";
    const { t } = useTranslation();

    window.onscroll = function () {
        scrollFunction();
    };

    const scrollFunction = () => {
        const element = document.getElementById("back-to-top");
        if (element) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    };

    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };
    const [activeComponent, setActiveComponent] = useState('');

    const showComponent = (componentName: string) => {
        setActiveComponent(componentName);
    };

    return (
        <React.Fragment>
            <div className="layout-wrapper landing">
                <Navbar />
                <section className="section pb-0 hero-section" id="hero">
                    <div className="bg-overlay bg-overlay-pattern"></div>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8} sm={10}>
                                <div className="text-center mt-lg-5 pt-5">
                                    <button onClick={() => showComponent('A')}>{t('Imagine')}</button>
                                    <button onClick={() => showComponent('B')}>{t('Blend')}</button>
                                    <button onClick={() => showComponent('C')}>{t('Describe')}</button>

                                    {activeComponent === 'A' && <FormImagine />}
                                    {activeComponent === 'B' && <FormBlend />}
                                    {activeComponent === 'C' && <FormDescribe />}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer />
                <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
                    <i className="ri-arrow-up-line"></i>
                </button>
            </div>
        </React.Fragment>
    );
};

export default Index;
